import React, { useContext } from "react";
import styled from "styled-components";
import { Header2, Header4, Paragraph } from "../Text";
import { PopUpContext } from "./showStory";
import { AiOutlineCloseCircle } from "react-icons/ai";
import FirebaseImage from "../firebaseImage";

export function PopUp() {
  const { showStory, setShowStory, story } = useContext(PopUpContext);

  if (!showStory) {
    return null;
  }

  return (
    <>
      <Background onClick={() => setShowStory(false)} />
      <PopUpContainer>
        {story && (
          <>
            {story.image && (
              <ImageContainer>
                <FirebaseImage
                  image={story.image}
                  alt={story.alt}
                  desktopRes="500px"
                />
              </ImageContainer>
            )}
            <TextContainer>
              <Header2>{story.title}</Header2>
              <Header4>{story.subTitle}</Header4>
              <Paragraph>{story.body}</Paragraph>
            </TextContainer>
          </>
        )}
        <CloseIcon size={32} onClick={() => setShowStory(false)} />
      </PopUpContainer>
    </>
  );
}

const CloseIcon = styled(AiOutlineCloseCircle)`
  position: absolute;

  top: 5px;
  right: 5px;

  color: black;

  &:hover {
    opacity: 0.6;
  }
`;

const ImageContainer = styled.div`
  min-width: 300px;
  width: 100%;
  max-width: 500px;
  /* height: 100%; */

  object-fit: cover;

  border-top-left-radius: 5px;

  border-top-right-radius: 0;
  border-bottom-left-radius: 5px;

  @media (max-width: 600px) {
    height: auto;
    width: 100%;
    max-width: none;

    border-top-right-radius: 5px;
    border-bottom-left-radius: 0;
  }
`;

const TextContainer = styled.div`
  min-width: 300px;
  width: fit-content;
  padding: 10px;

  @media (max-width: 600px) {
    min-width: 200px;
    max-width: 95vw;
  }
`;

const Background = styled.div`
  position: fixed;

  height: 100vh;
  width: 100vw;

  display: inline-flex;
  justify-content: center;
`;

const PopUpContainer = styled.section`
  background-color: white;

  display: inline-flex;
  flex-direction: row;

  border-radius: 5px;

  position: fixed;
  top: 20vh;

  min-height: 400px;
  height: fit-content;
  max-height: 600px;

  min-width: 300px;
  width: fit-content;
  max-width: 800px;

  box-shadow: 5px 15px 15px #1213134c;

  @media (max-width: 600px) {
    flex-direction: column;

    top: 5vh;

    min-width: 70vw;
    width: fit-content;
    max-width: 80vw;

    min-height: 70vh;
    height: fit-content;
    max-height: 80vh;
  }

  animation: fadein 125ms ease-in-out;

  @keyframes fadein {
    from {
      opacity: 0;
      transform: scale(0.5);
    }
    to {
      opacity: 1;
      transform: scale(1);
    }
  }
`;
