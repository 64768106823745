import React from "react";
import { Grid } from "../Page";
import { pageData, PageMetaData } from "../../types/pages";
import { ComponentRenderer } from "./ComponentRenderer";

export function Display({
  data,
  metaData,
}: {
  data?: pageData[];
  metaData?: PageMetaData;
}) {
  if (!data || !metaData) return null;

  if (metaData.grid) {
    return (
      <Grid>
        <ComponentsRenderer data={data} />
      </Grid>
    );
  }

  return <ComponentsRenderer data={data} />;
}

export function ComponentsRenderer({ data }: { data: pageData[] }) {
  return (
    <>
      {data.map((props) => (
        <ComponentRenderer {...props} key={props._id} />
      ))}
    </>
  );
}
