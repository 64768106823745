import React, { useState, createContext } from "react";

interface Story {
  title: string;
  subTitle: string;
  body: string;
  image: string;
  alt: string;
}

interface Store {
  showStory: boolean;
  setShowStory: React.Dispatch<React.SetStateAction<boolean>>;
  story?: Story;
  setStory: React.Dispatch<React.SetStateAction<Story | undefined>>;
}

export const PopUpContext = createContext({} as Store);

export function PopUpProvider({
  children,
}: {
  children: JSX.Element;
}): JSX.Element {
  const [showStory, setShowStory] = useState<boolean>(false);
  const [story, setStory] = useState<Story>();

  const store: Store = {
    showStory,
    setShowStory,
    story,
    setStory,
  };

  return (
    <PopUpContext.Provider value={store as Store}>
      {children}
    </PopUpContext.Provider>
  );
}
