import React from "react";
import styled from "styled-components";
import FirebaseImage from "../../firebaseImage";

export function Polaroid({
  image = "",
  imageAlt = "",
  color = "#fff",
  children = <></>,
}: {
  image?: string;
  imageAlt?: string;
  color?: string;
  children?: JSX.Element[] | JSX.Element;
}) {
  return (
    <Section style={{ backgroundColor: color }}>
      {image && (
        <ImageContainer>
          <FirebaseImage
            image={image}
            alt={imageAlt}
            desktopRes="280px"
            mobileRes="280px"
          />
        </ImageContainer>
      )}
      <TextContainer>{children}</TextContainer>
    </Section>
  );
}

const Section = styled.section`
  display: inline-flex;
  flex-direction: column;

  height: fit-content;
  min-height: 510px;
  width: fit-content;

  margin: 32px;

  transition: opacity 0.2s ease-in-out;

  box-shadow: 0px 5px 5px #1213134c;
`;

const ImageContainer = styled.div`
  height: 280px;
  width: 280px;
  margin: 20px;
`;

const TextContainer = styled.div`
  padding: 12px;
  max-width: 296px;
`;
