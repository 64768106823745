import styled from "styled-components";

export const Grid = styled.main`
  display: grid;
  grid-template-columns: 25% 25% 25% 25%;

  width: fit-content;
  justify-items: center;

  @media (max-width: 1600px) {
    grid-template-columns: 33% 33% 33%;
  }

  @media (max-width: 1200px) {
    grid-template-columns: 50% 50%;
  }

  @media (max-width: 800px) {
    grid-template-columns: 100%;
  }
`;

export const Page = styled.main`
  display: contents;
`;
