import React from "react";
import styled from "styled-components";

import SEO from "../components/DynamicTitle";
import { Footer } from "../components/contact/contact";
import Layout from "../components/Layout";

import ComponentsRenderer from "../components/ComponentsRenderer";
import PopUp, { PopUpProvider } from "../components/popUp";

import { PageMetaData, pageData } from "../types/pages";
import { Title } from "../components/Text";

interface DynamicPageProps {
  pageContext: {
    metaData?: PageMetaData;
    data?: pageData[];
  };
}

export default function DynamicPage(props: DynamicPageProps) {
  const { pageContext } = props;
  const { metaData, data } = pageContext;

  return (
    <PopUpProvider>
      <>
        <PopUpContainer>
          <PopUp />
        </PopUpContainer>
        <Layout>
          <SEO {...metaData} />
          <Title>{metaData.title}</Title>
          <ComponentsRenderer data={data} metaData={metaData} />
          <Footer />
        </Layout>
      </>
    </PopUpProvider>
  );
}

const PopUpContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`;
