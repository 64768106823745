import React from "react";
import { Paragraph, FancySubHeader, Header3 } from "../Text";
import { pageData } from "../../types/pages";

import ImageAndInfo from "../complex/ImageAndInfo";
import Polaroid from "../complex/polaroid";
import CircleWithText from "../complex/CircleWithText";

export function ComponentRenderer({
  image,
  imageAlt,
  imageRight,
  color,
  whiteText = false,
  disabled = false,
  header,
  subHeader,
  text,
  type,
}: pageData) {
  if (disabled) {
    return null;
  } else if (type === "ImageAndInfo") {
    return (
      <ImageAndInfo
        image={image}
        imageAlt={imageAlt}
        color={color}
        imageRight={imageRight}
      >
        <FancySubHeader white={whiteText}>{header}</FancySubHeader>
        <Paragraph white={whiteText}>{text}</Paragraph>
      </ImageAndInfo>
    );
  } else if (type === "Polaroid") {
    return (
      <Polaroid image={image} imageAlt={imageAlt} color={color}>
        <Header3 white={whiteText}>{header}</Header3>
        <Paragraph white={whiteText}>{text}</Paragraph>
      </Polaroid>
    );
  } else if (type === "CircleWithText") {
    return (
      <CircleWithText
        image={image}
        title={header}
        subTitle={subHeader}
        text={text}
      />
    );
  }

  return null;
}
