import React from "react";
import styled from "styled-components";
import FirebaseImage from "../../firebaseImage";

export function ImageAndInfo({
  image = "",
  imageAlt = "",
  color = "#fff",
  children = <></>,
  imageRight = false,
}: {
  image?: string;
  imageAlt?: string;
  color?: string;
  children?: JSX.Element[] | JSX.Element;
  imageRight?: boolean;
}) {
  return (
    <Section imageRight={imageRight}>
      {image && (
        <ImageSection>
          <FirebaseImage image={image} alt={imageAlt} />
        </ImageSection>
      )}
      <TextSection style={{ backgroundColor: color }}>{children}</TextSection>
    </Section>
  );
}

const Section = styled.section`
  display: inline-flex;
  flex-direction: ${({ imageRight }: { imageRight?: boolean }) =>
    imageRight ? "row-reverse" : "row"};

  width: 100%;
  height: 500px;

  @media (max-width: 600px) {
    flex-direction: column;
    height: fit-content;
  }
`;

const TextSection = styled.div`
  width: calc(50% - (16vw - 20px));
  height: calc(500px - (16vw - 20px));

  padding: calc(8vw - 10px);

  justify-content: center;
  display: inline-flex;
  flex-direction: column;

  @media (max-width: 600px) {
    width: calc(100% - (16vw - 20px));
    min-height: calc(300px - (16vw - 20px));
    height: fit-content;

    padding: calc(8vw - 10px);
  }
`;

const ImageSection = styled.div`
  height: 500px;
  width: 50%;

  @media (max-width: 600px) {
    height: 100%;
    width: 100%;
    display: contents;
  }
`;

// const Action = styled(Header2)`
//   border: 2px solid var(--accent);
//   border-radius: 6px;

//   transition: all 0.2s ease-in-out;
//   display: inline;
//   padding: 4px;

//   &:hover {
//     background-color: var(--accent);
//     color: var(--background);
//     opacity: 0.9;
//   }
// `;
