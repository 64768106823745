import React, { useContext } from "react";
import { Header2, Header3 } from "../../Text";
import { PopUpContext } from "../../popUp/showStory";
import FirebaseImage from "../../firebaseImage";
import styled from "styled-components";

export function CircleWithText({
  image = "",
  imageAlt = "",
  title = "",
  subTitle = "",
  text = "",
}: {
  image?: string;
  imageAlt?: string;
  title?: string;
  subTitle?: string;
  text?: string;
}) {
  const { setShowStory, setStory } = useContext(PopUpContext);

  return (
    <>
      <Section
        onClick={() => {
          setStory({ title, subTitle, image, alt: imageAlt, body: text });
          setShowStory(true);
        }}
      >
        {image && (
          <ImageContainer>
            <FirebaseImage
              image={image}
              alt={imageAlt}
              style={{ borderRadius: "50%" }}
              desktopRes="200px"
              mobileRes="200px"
            />
          </ImageContainer>
        )}
        <Header2 style={{ textAlign: "center", fontSize: 32, fontWeight: 300 }}>
          {title}
        </Header2>
        <Header3 style={{ textAlign: "center" }}>{subTitle}</Header3>
      </Section>
    </>
  );
}

const Section = styled.section`
  display: inline-flex;
  flex-direction: column;

  height: fit-content;
  width: fit-content;

  transition: opacity 0.2s ease-in-out;

  margin: 32px;

  &:hover {
    /* opacity: 0.8; */
    cursor: pointer;
  }
`;

const ImageContainer = styled.div`
  height: 200px;
  width: 200px;

  margin: 20px;
  align-self: center;
`;
